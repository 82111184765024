@import url('https://fonts.googleapis.com/css2?family=Changa+One&family=Montserrat:wght@400;700&display=swap');

@keyframes hide_show_sidebar {
    from {left: 0px;}
    to {left: 256px;}
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes mystery-animation {
    0% {
      opacity: 1;
    }
    5% {
      transform: translateX(-10px);
    }
    10% {
      transform: translateX(0px);
    }
    15% {
      transform: translateX(-10px);
    }
    20% {
      transform: translateX(0px);
    }
    25% {
      opacity: 0.7;
    }
    30% {
      transform: translateX(0px);
    }
    35% {
      transform: translateX(10px);
    }
    40% {
      transform: translateX(0px);
    }
    45% {
      transform: translateX(10px);
    }
    50% {
      opacity: 1;
      transform: translateX(0px);
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }


