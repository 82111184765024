.rc-tooltip.rc-tooltip-zoom-appear,
.rc-tooltip.rc-tooltip-zoom-enter {
  opacity: 0;
}
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    /* transform: scale(0, 0); */
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    /* transform: scale(1, 1); */
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    /* transform: scale(1, 1); */
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    /* transform: scale(0, 0); */
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  line-height: 1.5;
  font-size: 12px;
  padding: 1px;
  /* transition: opacity 0.7s ease, visibility 0.7s ease;
  visibility: hidden;
  opacity: 0; */
}
.rc-tooltip:hover {
  visibility: visible;
  opacity: 1;
}

.rc-tooltip-hidden {
    transition: opacity 0.7s ease, visibility 0.7s ease;
  visibility: hidden;
  opacity: 0;
}
.rc-tooltip-hidden:hover {
    visibility: visible;
    opacity: 1;
}

.rc-tooltip-inner {
  padding: 12px 12px 12px 16px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background: linear-gradient(360deg, #060027 -4.96%, #00255F 106.77%);
  mix-blend-mode: normal;
  opacity: 0.9;
  visibility: visible;
  border-radius: 3px;
  min-height: 34px;
  position: relative;
}

@media (max-width: 768px) {
    .rc-tooltip-inner {
        transform: none;
    }
}

@media (max-width: 768px) {
    .rc-tooltip-inner {
        transform: none;
    }
}

.rc-tooltip-inner::before {
    content: '';
    position: absolute;
    background: #0057E2;
    width: 8px;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.rc-tooltip-arrow,
.rc-tooltip-arrow-inner {
  position: absolute;
  width: 76px;
  height: 2px;
  background-color: #fff;
  top: 0;
  /* transform: rotate(140deg); */
  border-color: transparent;
  border-style: solid;
}

@media (max-width: 768px) {
    .rc-tooltip-arrow,
    .rc-tooltip-arrow-inner {
    display: none;
    }
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: -5px;
  margin-left: -6px;
}
.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  bottom: 1px;
  margin-left: -6px;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: -5px;
}
.rc-tooltip-placement-right .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
  left: 1px;
  margin-top: -6px;
  border-width: 2px 2px 2px 2px;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-right .rc-tooltip-inner  {
  transform: translate(85px, -100%);

}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: -5px;
  margin-top: -6px;
  border-width: 2px 2px 2px 2px;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
  right: 1px;
  margin-top: -6px;
  border-width: 2px 2px 2px 2px;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: -5px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #b1b1b1;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
  top: 1px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}
